/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  Block,
  BlockTitle,
  FieldDisplayer,
  FieldDisplayerCard,
  MonthlySavingsDisplayerCard,
} from 'app/components/common';
import { getWording } from 'app/constants/wording/wording';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { isContribution, isTransfer } from 'app/utils/apvContribution';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import {
  RegimeLiteral,
  SavingsOption,
  WithAPVTransfer,
  WithExtraordinaryContribution,
} from 'app/utils/types';
import React from 'react';

interface TransferForSavingsCapacityCardsProps {
  monthly_savings: number
  afp_annuity: number
  afp_with_transfer_apv_annuity: number
  expected_annuity: number
  apv_regime: Uppercase<RegimeLiteral>
}

function TransferForSavingsCapacityCards({
  afp_annuity,
  afp_with_transfer_apv_annuity,
  apv_regime,
  expected_annuity,
  monthly_savings,
}: TransferForSavingsCapacityCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <Block className="mb-3">
      <BlockTitle
        extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
        primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
        secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
      />

      <div className="row gy-3 gx-4 justify-content-center">
        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-wad-of-money.svg"
            renderText={() => (
              <>
                <span className="text-warning">
                  {`Sólo con ${wording.your} cotización obligatoria, `}
                </span>
                <span>{`${wording.your} pensión podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                secondaryText={{ content: r(afp_annuity), color: 'text-dark' }}
              />
            )}
          />
        </div>

        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-banknote.svg"
            renderText={() => (
              <>
                <span className="text-info">{`Si a eso le ${wording.append} ${wording.yours} actuales ahorros APV, `}</span>
                <span>{`${wording.your} pensión podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                primaryText={{ content: currencyPrefix, color: 'text-info' }}
                secondaryText={{ content: r(afp_with_transfer_apv_annuity), color: 'text-dark' }}
              />
            )}
          />
        </div>

        {monthly_savings !== 0 && (
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-hand-money.svg"
              renderText={() => (
                <>
                  <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                  <span>{`${wording.your} pensión al mes podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                  primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                  secondaryText={{ content: r(expected_annuity), color: 'text-dark' }}
                />
              )}
            />
          </div>
        )}
      </div>
    </Block>
  );
}

interface BaseSavingsCapacityCardsProps {
  apv_regime: Uppercase<RegimeLiteral>
  afp_annuity: number
  pension_rise: number
}

function BaseSavingsCapacityCards({
  afp_annuity, apv_regime, pension_rise,
}: BaseSavingsCapacityCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <Block className="mb-3">
      <BlockTitle
        extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
        primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
        secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
      />

      <div className="row gy-3 gx-4 justify-content-center">
        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-wad-of-money.svg"
            renderText={() => (
              <>
                <span className="text-warning">
                  {`Sólo con ${wording.your} cotización obligatoria, `}
                </span>
                <span>{`${wording.your} pensión podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                secondaryText={{ content: r(afp_annuity), color: 'text-dark' }}
              />
            )}
          />
        </div>

        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-hand-money.svg"
            renderText={() => (
              <>
                <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                <span className="d-block">{`${wording.your} pensión al mes podría `}</span>
                <span>{'ser de: '}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{ content: r(afp_annuity + pension_rise), color: 'text-dark' }}
              />
            )}
          />
        </div>
      </div>
    </Block>
  );
}

interface SavingsCapacityCardsProps extends
  TransferForSavingsCapacityCardsProps,
  BaseSavingsCapacityCardsProps,
  WithAPVTransfer,
  WithExtraordinaryContribution {}

function SavingsCapacityCards({
  afp_annuity,
  apv_regime,
  pension_rise,
  afp_with_transfer_apv_annuity,
  expected_annuity,
  apv_transfer_amount,
  apv_transfer_origin,
  initial_contribution,
  monthly_savings,
}: SavingsCapacityCardsProps) {
  return isTransfer({ apv_transfer_amount, apv_transfer_origin, initial_contribution }) ? (
    <TransferForSavingsCapacityCards
      monthly_savings={monthly_savings}
      afp_annuity={afp_annuity}
      apv_regime={apv_regime}
      afp_with_transfer_apv_annuity={afp_with_transfer_apv_annuity}
      expected_annuity={expected_annuity}
    />
  )
    : (
      <BaseSavingsCapacityCards
        afp_annuity={afp_annuity}
        apv_regime={apv_regime}
        pension_rise={pension_rise}
      />
    );
}

interface BaseIdealPensionCardsProps extends BaseSavingsCapacityCardsProps {
  monthly_savings: number
}

function BaseIdealPensionCards({
  afp_annuity, apv_regime, monthly_savings, pension_rise,
}: BaseIdealPensionCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <>
      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
          primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
          secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
        />

        <div className="row gy-3 gx-4 justify-content-center">
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-wad-of-money.svg"
              renderText={() => (
                <>
                  <span className="text-warning">
                    {`Sólo con ${wording.your} cotización obligatoria, `}
                  </span>
                  <span>{`${wording.your} pensión podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                  primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                  secondaryText={{ content: r(afp_annuity), color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-hand-money.svg"
              renderText={() => (
                <>
                  <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                  <span className="d-block">{`${wording.your} pensión al mes podría `}</span>
                  <span>{'ser de: '}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                  primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                  secondaryText={{ content: r(afp_annuity + pension_rise), color: 'text-dark' }}
                />
              )}
            />
          </div>
        </div>
      </Block>

      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center mb-3"
          primaryText={{ content: `¿Cómo lo ${wording.willReach}?`, color: 'text-primary' }}
        />

        <div className="row">
          <div className="col-md-6 mx-auto">
            <MonthlySavingsDisplayerCard
              apv_regime={apv_regime}
              monthly_savings={monthly_savings}
            />
          </div>
        </div>
      </Block>
    </>
  );
}

interface TransferIdealPensionCardsProps extends TransferForSavingsCapacityCardsProps {
  monthly_savings: number
}

function TransferIdealPensionCards({
  apv_regime,
  afp_annuity,
  afp_with_transfer_apv_annuity,
  expected_annuity,
  monthly_savings,
}: TransferIdealPensionCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <>
      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
          primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
          secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
        />

        <div className="row gy-3 gx-4 justify-content-center">
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-wad-of-money.svg"
              renderText={() => (
                <>
                  <>
                    <span className="text-warning">
                      {`Sólo con ${wording.your} cotización obligatoria, `}
                    </span>
                    <span>{`${wording.your} pensión podría ser de:`}</span>
                  </>

                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                  primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                  secondaryText={{ content: r(afp_annuity), color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-banknote.svg"
              renderText={() => (
                <>
                  <span className="text-info">{`Si a eso le ${wording.append} ${wording.yours} actuales ahorros APV, `}</span>
                  <span>{`${wording.your} pensión podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                  primaryText={{ content: currencyPrefix, color: 'text-info' }}
                  secondaryText={{ content: r(afp_with_transfer_apv_annuity), color: 'text-dark' }}
                />
              )}
            />
          </div>

        </div>
      </Block>

      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center mb-3"
          primaryText={{ content: `¿Cómo lo ${wording.willReach}?`, color: 'text-primary' }}
        />

        <div className="row gy-3 gx-4 justify-content-center">
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-hand-money.svg"
              renderText={() => (
                <>
                  <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                  <span>{`${wording.your} pensión al mes podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                  primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                  secondaryText={{ content: r(expected_annuity), color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <MonthlySavingsDisplayerCard
              apv_regime={apv_regime}
              monthly_savings={monthly_savings}
            />
          </div>
        </div>
      </Block>
    </>
  );
}

interface IdealPensionCardsProps extends
  BaseIdealPensionCardsProps,
  TransferIdealPensionCardsProps,
  WithAPVTransfer,
  WithExtraordinaryContribution {}

function IdealPensionCards({
  afp_annuity,
  apv_regime,
  apv_transfer_amount,
  apv_transfer_origin,
  afp_with_transfer_apv_annuity,
  expected_annuity,
  monthly_savings,
  pension_rise,
  initial_contribution,
  initial_contribution_kind,
  agreed_deposits,
  agreed_deposits_kind,
}: IdealPensionCardsProps) {
  const contributionData = {
    apv_transfer_amount,
    apv_transfer_origin,
    initial_contribution,
    initial_contribution_kind,
    agreed_deposits,
    agreed_deposits_kind,
  };

  return (isTransfer(contributionData) || isContribution(contributionData)) ? (
    <TransferIdealPensionCards
      afp_annuity={afp_annuity}
      apv_regime={apv_regime}
      monthly_savings={monthly_savings}
      afp_with_transfer_apv_annuity={afp_with_transfer_apv_annuity}
      expected_annuity={expected_annuity}
    />
  )
    : (
      <BaseIdealPensionCards
        afp_annuity={afp_annuity}
        apv_regime={apv_regime}
        monthly_savings={monthly_savings}
        pension_rise={pension_rise}
      />
    );
}

interface SavingsUnbundlingCardsProps extends
  IdealPensionCardsProps,
  SavingsCapacityCardsProps,
  WithAPVTransfer,
  WithExtraordinaryContribution {
  savings_option: SavingsOption
}

export function ProvisionalSavingsUnbundlingCards({
  afp_annuity,
  apv_regime,
  apv_transfer_amount,
  apv_transfer_origin,
  initial_contribution,
  initial_contribution_kind,
  agreed_deposits,
  agreed_deposits_kind,
  afp_with_transfer_apv_annuity,
  expected_annuity,
  monthly_savings,
  pension_rise,
  savings_option,
}: SavingsUnbundlingCardsProps) {
  return savings_option === 'ideal_pension' ? (
    <IdealPensionCards
      afp_annuity={afp_annuity}
      afp_with_transfer_apv_annuity={afp_with_transfer_apv_annuity}
      apv_regime={apv_regime}
      apv_transfer_amount={apv_transfer_amount}
      apv_transfer_origin={apv_transfer_origin}
      expected_annuity={expected_annuity}
      initial_contribution={initial_contribution}
      initial_contribution_kind={initial_contribution_kind}
      agreed_deposits={agreed_deposits}
      agreed_deposits_kind={agreed_deposits_kind}
      monthly_savings={monthly_savings}
      pension_rise={pension_rise}
    />
  )
    : (
      <SavingsCapacityCards
        monthly_savings={monthly_savings}
        afp_annuity={afp_annuity}
        afp_with_transfer_apv_annuity={afp_with_transfer_apv_annuity}
        apv_regime={apv_regime}
        apv_transfer_amount={apv_transfer_amount}
        apv_transfer_origin={apv_transfer_origin}
        expected_annuity={expected_annuity}
        initial_contribution={initial_contribution}
        initial_contribution_kind={initial_contribution_kind}
        agreed_deposits={agreed_deposits}
        agreed_deposits_kind={agreed_deposits_kind}
        pension_rise={pension_rise}
      />
    );
}
