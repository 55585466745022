import React from 'react';
import { IconProps } from 'app/utils/types';
import { Card } from './Card';

interface CTACardProps {
  renderContent: () => JSX.Element
  renderCTA: () => JSX.Element
  icon: IconProps
}

export function CTACard({ icon, renderCTA, renderContent }: CTACardProps) {
  return (
    <Card>
      <section className="d-flex gap-2 flex-wrap flex-md-nowrap align-items-center justify-content-center">
        <img src={icon.src} alt={icon.alt} />

        <div className="d-flex flex-column align-items-center justify-content-center gap-2 w-100">
          <div>
            {renderContent()}
          </div>

          <div>
            {renderCTA()}
          </div>
        </div>
      </section>
    </Card>
  );
}
