import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { StackedBarChartConfig } from 'app/utils/types';
import { getQueryParams } from 'app/utils/queryParams';
import { themeColors } from 'app/utils/colors';

import { getPercentage } from 'app/utils/charts';
import { getWording } from 'app/constants/wording/wording';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r, withPrefix } from 'app/utils/hooks/useTransformCurrency';
import {
  mutualFundsService,
  MutualFundsSimulationVariants,
} from './mutualFundsService';
import { chartDataGenerator } from './chartDataGenerators';

export function useMutualFunds() {
  const [showEmptyContent, setShowEmptyContent] = useState<boolean>(false);
  const { is_empty, ...payloadParameters } = getQueryParams<MutualFundsSimulationVariants>();
  const { simulate } = payloadParameters;
  const { wording } = getWording();
  const { handleInitCurrencyData } = useCurrencyContext();

  const {
    mutate: dispatchSimulation,
    data: simulationResult,
    isError,
    isLoading,
  } = useMutation({
    mutationFn: async (payload: MutualFundsSimulationVariants) => (
      await mutualFundsService.simulate(payload)).data,

    onSuccess: (data) => {
      const { currency, uf_value } = data;
      handleInitCurrencyData(currency, uf_value);
    },
  });

  const chartData = useMemo(() => {
    if (!simulationResult) return null;

    const {
      totalSavings,
      profit,
    } = chartDataGenerator[simulate](payloadParameters, simulationResult);

    const TOTAL_SUM = profit + totalSavings;

    const chartConfig: StackedBarChartConfig = {
      name: 'MUTUAL_FUNDS',
      stacks: [
        {
          color: themeColors.info,
          field: 'savings',
          label: (color) => (
            <div style={{ color }}>
              <div className="fs-6">💵</div>
              <div className="fw-bold fs-small">{`${r(totalSavings, withPrefix)}`}</div>
              <div className="fs-smaller">{`Saldrán de ${wording.your} bolsillo`}</div>
            </div>
          ),
          value: getPercentage(totalSavings, TOTAL_SUM),
        },
        {
          color: themeColors.secondary,
          field: 'rentability',
          label: (color) => (
            <div style={{ color }}>
              <div className="fs-6">📈</div>
              <div className="fw-bold fs-small">{`${r(profit, withPrefix)}`}</div>
              <div className="fs-smaller">{`Lo que ${wording.couldWin} ganar por rentabilidad`}</div>
            </div>
          ),
          value: getPercentage(profit, TOTAL_SUM),
        },
      ],
    };

    return chartConfig;
  }, [simulationResult]);

  useEffect(() => {
    if (is_empty) {
      setShowEmptyContent(Boolean(is_empty));
      return;
    }

    dispatchSimulation({
      ...payloadParameters,

      // If `risk_profile` is not injected in the url as query parameter; automatically
      // takes `moderate` as default value

      // We need to pass it as default value because if we make `risk_profile` mandatory,
      // multiple applications which consume simulators will break
      risk_profile: payloadParameters.risk_profile ?? 'moderate',
    });
  }, []);

  return {
    chartData,
    payloadParameters,
    simulationResult,
    isError,
    isLoading,
    showEmptyContent,
  };
}
