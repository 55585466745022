/* eslint-disable react/no-unstable-nested-components */
import {
  FieldDisplayer,
  Block,
  BlockTitle,
  CardChart,
  LoadingSpinner,
} from 'app/components/common';
import React from 'react';
import {
  SimulationProductError, StackedBarChartConfig, WithCurrencySwitch,
} from 'app/utils/types';
import { HorizontalStackBarChart, VerticalStackBarChart } from 'app/components/charts';
import { getIcon } from 'app/utils/staticStorage';
import { MEDIA_QUERIES, useMediaQuery } from 'app/utils/hooks/useMediaQuery';
import { getWording } from 'app/constants/wording/wording';
import { getQueryParams } from 'app/utils/queryParams';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { CurrencySwitch } from 'app/components/common/CurrencySwitch';
import { DisclaimersList } from 'app/components/common/DisclaimersList';
import { InformativeDeclarationDisclaimer } from 'app/components/common/InformativeDeclarationDisclaimer';
import { RISK_PROFILE_METADATA } from 'app/utils/constants';
import { formatPercentage } from 'app/utils/formatters';
import { AxiosError } from 'axios';
import { ProvisionalSavingsUnbundlingCards } from './ProvisionalSavingsUnbundlingCards';
import { useProvisionalSavings } from './useProvisionalSavings';
import { ProvisionalSavingsPayload, ProvisionalSavingsResponse } from './provisionalSavingsService';
import { MonthlySavingsSection } from './SavingsCardsSection';
import { SimulationError } from '../SimulationError';

interface ProvisionalSavingsContentProps extends WithCurrencySwitch {
  simulationResult: ProvisionalSavingsResponse,
  chartData: StackedBarChartConfig
  containerClassname?: string
}

function ProvisionalSavingsContent({
  simulationResult,
  chartData,
  containerClassname,
  showCurrencySwitch,
}: ProvisionalSavingsContentProps) {
  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);
  const {
    gender,
    apv_regime,
    risk_profile,
    savings_option,
    apv_transfer_origin,
    apv_transfer_amount,
    initial_contribution,
    initial_contribution_kind,
    agreed_deposits,
    agreed_deposits_kind,
    expected_retirement,
  } = getQueryParams<ProvisionalSavingsPayload>();
  const { wording } = getWording();
  const { currencyPrefix } = useCurrencyContext();

  const {
    afp_annuity,
    pension_rise,
    yearly_benefit,
    total_yearly_savings,
    monthly_savings,
    monthly_personal_payment,
    monthly_tax_deduction,
    afp_with_transfer_apv_annuity,
    expected_annuity,
    total_savings,
  } = simulationResult;

  const {
    label: riskProfileLabel,
    percentage: riskProfilePercentage,
    afpPercentage: afpRiskProfilePercentage,
  } = RISK_PROFILE_METADATA[risk_profile];

  function getRetirementYears() {
    if (expected_retirement) return expected_retirement;

    if (gender === 'MALE') return 65;

    return 60;
  }

  const showMonthlySavings = monthly_savings > 0;

  return (
    <section className={containerClassname ?? 'simulation-renderer'}>
      {showCurrencySwitch && <CurrencySwitch />}

      <ProvisionalSavingsUnbundlingCards
        afp_with_transfer_apv_annuity={afp_with_transfer_apv_annuity}
        expected_annuity={expected_annuity}
        afp_annuity={afp_annuity}
        apv_regime={apv_regime}
        apv_transfer_amount={apv_transfer_amount}
        apv_transfer_origin={apv_transfer_origin}
        initial_contribution={initial_contribution}
        initial_contribution_kind={initial_contribution_kind}
        agreed_deposits={agreed_deposits}
        agreed_deposits_kind={agreed_deposits_kind}
        monthly_savings={monthly_savings}
        pension_rise={pension_rise}
        savings_option={savings_option}
      />

      {showMonthlySavings && (
        <Block className="mb-3">
          <MonthlySavingsSection
            regime={apv_regime}
            monthly_benefit={yearly_benefit / 12}
            total_monthly_benefit={total_yearly_savings / 12}
            monthly_savings={monthly_savings}
            monthly_personal_payment={monthly_personal_payment}
            monthly_tax_deduction={monthly_tax_deduction}
          />
        </Block>
      )}

      <Block className="mb-3">
        <BlockTitle
          extraClassName="fs-5 text-center mb-2 lh-base"
          primaryText={{ content: `Así se distribuirán ${wording.yours} ahorros a lo largo del tiempo hasta cuando ${wording.wouldBe} ${wording.retirement} a los `, color: 'text-primary' }}
          secondaryText={{ content: `${getRetirementYears()} años:` }}
        />

        <CardChart
          renderFieldDisplayer={() => (
            <div className="mb-3 d-flex justify-content-center">
              <FieldDisplayer
                overwrittenClassName="fw-bold px-2_5 py-2 d-inline-block rounded-4 fs-1 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{
                  content: r(total_savings),
                  color: 'text-dark',
                }}
              />
            </div>
          )}
          renderChart={() => (
            isMobile
              ? <VerticalStackBarChart chartData={chartData} />
              : (
                <HorizontalStackBarChart
                  chartData={chartData}
                  labelCol={3}
                />
              )
          )}
          renderText={() => (
            <div className="text-center">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span className="text-secondary">
                {savings_option === 'savings_capacity' && monthly_savings === 0
                  ? 'total de ahorros previsionales '
                  : 'total de ahorros con APV '}
              </span>
              <span>podría ser de:</span>
            </div>
          )}
        />
      </Block>

      <Block className="mb-3">
        <DisclaimersList
          items={[
            apv_regime === 'A'
              ? 'Recuerda que el beneficio tributario dependerá de la tasa de impuestos del cliente.'
              : 'Para Régimen B el beneficio tributario tiene un tope de hasta 600 UF anuales.',
            `Estos cálculos se realizan con una rentabilidad ${riskProfileLabel} del ${formatPercentage(riskProfilePercentage, { withFractionDigits: true, withTransform: true })} anual real para los fondos de APV.`,
            `Estos cálculos se realizan con una rentabilidad ${riskProfileLabel} del ${formatPercentage(afpRiskProfilePercentage, { withFractionDigits: true, withTransform: true })} anual real para los fondos de AFP (cuenta obligatoria). Esta rentabilidad no considera el descuento de comisiones por concepto de administración de la cuenta.`,
          ]}
        />
      </Block>

      <Block className="mb-3">
        <InformativeDeclarationDisclaimer />
      </Block>
    </section>
  );
}

function EmptyContent() {
  const { wording } = getWording();

  return (
    <section className="simulation-renderer h-100 d-flex align-items-center justify-content-center">
      <figure className="d-flex flex-column align-items-center justify-content-center mb-0">
        <img src={getIcon('rocket.svg')} alt="" className="mb-2_5" />

        <figcaption className="text-primary text-wrap text-center h6 fw-bold lh-base mb-0" style={{ width: 200 }}>
          {`Aquí verás cómo mejora ${wording.your} pensión`}
        </figcaption>
      </figure>
    </section>
  );
}

interface ProvisionalSavingsProps extends WithCurrencySwitch {
  containerClassname?: string
}

export function ProvisionalSavings({
  containerClassname,
  showCurrencySwitch,
}: ProvisionalSavingsProps) {
  const {
    simulationResult,
    chartData,
    error,
    isError,
    isLoading,
    isEmpty,
  } = useProvisionalSavings();

  if (isEmpty) {
    return <EmptyContent />;
  }

  if (simulationResult && chartData) {
    return (
      <ProvisionalSavingsContent
        showCurrencySwitch={showCurrencySwitch}
        simulationResult={simulationResult}
        chartData={chartData}
        containerClassname={containerClassname}
      />
    );
  }

  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;
  if (isError) return <SimulationError error={error as AxiosError<SimulationProductError>} />;

  return null;
}
