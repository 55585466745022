import { WithAPVTransfer, WithExtraordinaryContribution } from './types';

export function isTransfer<T extends WithAPVTransfer>(payload: T) {
  const { apv_transfer_amount, apv_transfer_origin } = payload;

  return apv_transfer_origin.length > 0 && apv_transfer_amount > 0;
}

export function isContribution<T extends WithExtraordinaryContribution>(payload: T) {
  const { initial_contribution } = payload;

  return initial_contribution > 0;
}

type isNoneContributionType = WithAPVTransfer & WithExtraordinaryContribution;

export function isNoneContribution<T extends isNoneContributionType>(payload: T) {
  return !isTransfer(payload) && !isContribution(payload);
}
