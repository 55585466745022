/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import {
  SimulationProductError, StackedBarChartConfig, WithCurrencySwitch, WithPolicyProjectionCTA,
} from 'app/utils/types';
import { getWording } from 'app/constants/wording/wording';
import {
  Block, BlockTitle, CardChart, FieldDisplayer, FieldDisplayerCard, LoadingSpinner,
} from 'app/components/common';
import { stringFormatter } from 'app/utils/formatters';
import { HorizontalStackBarChart, VerticalStackBarChart } from 'app/components/charts';
import { getIcon } from 'app/utils/staticStorage';
import { MEDIA_QUERIES, useMediaQuery } from 'app/utils/hooks/useMediaQuery';
import { AxiosError } from 'axios';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import { CurrencySwitch } from 'app/components/common/CurrencySwitch';
import { CTACard } from 'app/components/common/CTACard';
import { createUrlWithQueryParams, getQueryParams } from 'app/utils/queryParams';
import { POLICY_PROJECTION_URL } from 'app/utils/constants';
import { InformativeDeclarationDisclaimer } from 'app/components/common/InformativeDeclarationDisclaimer';
import { transformMonthsToYears } from 'app/utils/dates';
import clsx from 'clsx';
import { InsuredUniversityResponse } from './insuredUniversityService';
import { useInsuredUniversity } from './useInsuredUniversity';
import { SimulationError } from '../SimulationError';

interface InsuredUniversityContentProps extends WithCurrencySwitch, WithPolicyProjectionCTA {
  simulationResult: InsuredUniversityResponse
  chartData: StackedBarChartConfig
  containerClassname?: string
}

function InsuredUniversityContent({
  chartData,
  simulationResult,
  containerClassname,
  showCurrencySwitch,
  showPolicyProjectionCTA,
}: InsuredUniversityContentProps) {
  const isMobile = useMediaQuery(MEDIA_QUERIES.MOBILE);
  const {
    goal,
    timeframe,
    beneficiary_age,
    agreed_premium,
    average_monthly_cost,
    monthly_insurance_cost,
  } = simulationResult;
  const queryParameters = getQueryParams();
  const { wording } = getWording();
  const { currencyPrefix } = useCurrencyContext();

  return (
    <section className={containerClassname ?? 'simulation-renderer'}>
      {showCurrencySwitch && <CurrencySwitch />}

      <Block className="mb-3">
        <BlockTitle
          extraClassName="h6 mb-3"
          primaryText={{ content: `${stringFormatter.capitalize(wording.begin)} a ahorrar hoy y ${wording.insure} la `, color: 'text-primary' }}
          secondaryText={{ content: `universidad de ${wording.your} hijo:` }}
        />

        <div className="row gy-3 gx-4 justify-content-center">
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-hand-money-info.svg"
              renderText={() => (
                <>
                  <span className="text-capitalize">{`${wording.your} `}</span>
                  <span className="text-info">objetivo de ahorro </span>
                  <span>es de:</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                  primaryText={{ content: currencyPrefix, color: 'text-info' }}
                  secondaryText={{ content: r(goal), color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-user-heart.svg"
              renderText={() => (
                <>
                  <span>La edad actual de </span>
                  <span className="text-info">{`${wording.your} beneficiario `}</span>
                  <span>es de:</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                  primaryText={{ content: beneficiary_age.toString(), color: 'text-info' }}
                  secondaryText={{ content: 'años', color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-calendar.svg"
              renderText={() => (
                <>
                  <span className="text-capitalize">{`${wording.your} `}</span>
                  <span>objetivo se cumplirá en </span>
                  <span className="text-secondary">un período aproximado </span>
                  <span>de:</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                  primaryText={{ content: timeframe.toString(), color: 'text-dark' }}
                  secondaryText={{ content: 'meses', color: 'text-secondary' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-umbrella.svg"
              renderText={() => (
                <>
                  <span className="text-capitalize">{`${wording.your} `}</span>
                  <span>seguro tiene una </span>
                  <span className="text-secondary">prima total a pagar mensual </span>
                  <span>de:</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                  primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                  secondaryText={{ content: r(agreed_premium), color: 'text-dark' }}
                />
              )}
            />
          </div>
        </div>
      </Block>

      <Block className="mb-3">
        <CardChart
          renderText={() => (
            <span className="d-block mx-4 mb-2 fw-normal fs-small text-dark">
              <span className="text-capitalize">{`${wording.your} `}</span>
              <span className="text-secondary">total de ahorros </span>
              <span>{`en ${wording.your} Seguro Full Flexible Universitario `}</span>
              <span className="text-secondary fw-bold">{`a los ${transformMonthsToYears(timeframe)} años `}</span>
              <span>podría ser de: </span>
            </span>
          )}
          renderFieldDisplayer={() => (
            <div className="row justify-content-center">
              <FieldDisplayer
                overwrittenClassName="w-max-content fw-bold fs-1 px-2_5 py-2 d-inline-block rounded-4 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{ content: r(goal), color: 'text-dark' }}
              />
            </div>
          )}
          renderChart={() => (
            isMobile
              ? <VerticalStackBarChart chartData={chartData} />
              : (
                <HorizontalStackBarChart
                  chartData={chartData}
                  renderJoin={(columnWidth) => <div className={clsx('fs-small fw-bold text-dark', columnWidth)}>+</div>}
                />
              )
          )}
        />

      </Block>

      {showPolicyProjectionCTA && (
        <Block className="mb-3">
          <CTACard
            icon={{
              src: getIcon('circle-shield-star.svg'),
              alt: '',
            }}
            renderContent={() => (
              <div className="text-center fs-small">
                <p className="mb-2">
                  <span>El </span>
                  <span className="text-secondary">costo promedio mensual </span>
                  <span>{`de ${wording.your} Seguro Full Flexible Universitario sería de `}</span>
                  <span className="fw-bold">{`${r(average_monthly_cost, { withPrefix: true })} `}</span>
                  <span>y la </span>
                  <span className="text-secondary">prima básica </span>
                  <span>sería de </span>
                  <span className="fw-bold">{`${r(monthly_insurance_cost, { withPrefix: true })}. `}</span>
                </p>

                <p className="m-0">
                  <span>Revisa como irán variando a través de los años.</span>
                </p>
              </div>
            )}
            renderCTA={() => (
              <a
                target="_blank"
                className="btn btn-outline-primary"
                rel="noreferrer"
                href={createUrlWithQueryParams(
                  POLICY_PROJECTION_URL, queryParameters as Record<string, string>
                )}
              >
                Ver proyección
              </a>
            )}
          />
        </Block>
      )}

      <Block className="mb-3">
        <InformativeDeclarationDisclaimer />
      </Block>
    </section>
  );
}

function EmptyContent() {
  const { wording } = getWording();

  return (
    <section className="simulation-renderer h-100 d-flex align-items-center justify-content-center">
      <figure className="d-flex flex-column  align-items-center justify-content-center mb-0">
        <img src={getIcon('rocket.svg')} alt="" className="mb-2_5" />

        <figcaption className="text-primary text-wrap text-center h6 fw-bold lh-base mb-0" style={{ width: 200 }}>
          {`Aquí verás cómo mejora ${wording.your} pensión`}
        </figcaption>
      </figure>
    </section>
  );
}

interface InsuredUniversityProps extends WithCurrencySwitch, WithPolicyProjectionCTA {
  containerClassname?: string
}

export function InsuredUniversity({
  containerClassname,
  showCurrencySwitch,
  showPolicyProjectionCTA,
}: InsuredUniversityProps) {
  const {
    chartData,
    error,
    isEmpty,
    isError,
    isLoading,
    simulationResult,
  } = useInsuredUniversity();

  if (isEmpty) return <EmptyContent />;

  if (simulationResult && chartData) {
    return (
      <InsuredUniversityContent
        showPolicyProjectionCTA={showPolicyProjectionCTA}
        showCurrencySwitch={showCurrencySwitch}
        simulationResult={simulationResult}
        chartData={chartData}
        containerClassname={containerClassname}
      />
    );
  }

  if (isLoading) return <LoadingSpinner color="primary" size={256} borderWidth={4} />;
  if (isError) return <SimulationError error={error as AxiosError<SimulationProductError>} />;

  return null;
}
