import React from 'react';

import { StackedBarChartConfig } from 'app/utils/types';
import { BarChart, ResponsiveContainer, YAxis } from 'recharts';
import { StackBar } from './StackBar';

const DEFAULT_HEIGHT = 278;
interface VerticalStackBarChartProps {
  chartData: StackedBarChartConfig
  height?: number
}

export function VerticalStackBarChart({
  chartData,
  height = DEFAULT_HEIGHT,
}: VerticalStackBarChartProps) {
  return (
    <div className="d-flex mt-2">
      <ResponsiveContainer width={90} height={height}>
        <BarChart data={[chartData]}>

          {/* `domain` property needed for always take full height with chart */}
          <YAxis domain={[0, 'dataMax']} type="number" hide />
          {/* `reverse` needed in order to render up to down and sync with chart stack bar order */}
          {
            [...chartData.stacks].reverse().map((stack, index) => StackBar({
              stackId: chartData.name,
              isFirst: index === 0,
              isLast: index === chartData.stacks.length - 1,
              stack,
              withInternalLabel: false,
            }))
          }
        </BarChart>
      </ResponsiveContainer>

      <div className="d-flex flex-column ms-4">
        {chartData.stacks.map((stack) => {
          if (!stack.value) return null;

          return (
            <div
              key={stack.field}
              className="mb-3"
            >
              {stack.label(stack.color)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
