/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import { Apv, MutualFunds } from 'app/components/simulations';

import { SimulationContainerQueryParams, SimulationType } from 'app/utils/types';
import { getQueryParams } from 'app/utils/queryParams';
import { CurrencyContextProvider } from 'app/contexts/CurrencyContext';
import { InsuranceWithSavings } from '../simulations/insuranceWithSavings';
import { ProvisionalSavings } from '../simulations/provisionalSavings/ProvisionalSavings';
import { ApvLife } from '../simulations/apvLife/ApvLife';
import { FullFlexible } from '../simulations/fullFlexible';
import { InsuredUniversity } from '../simulations/insuredUniversity';

const simulationRenderMap: Record<SimulationType, <T>(props?: T) => JSX.Element> = {
  APV: () => <Apv />,
  INSURANCE_WITH_SAVINGS: () => <InsuranceWithSavings showCurrencySwitch />,
  MUTUAL_FUNDS: () => <MutualFunds showCurrencySwitch />,
  PROVISIONAL_SAVINGS: () => <ProvisionalSavings showCurrencySwitch />,
  APV_LIFE: () => <ApvLife showCurrencySwitch showPolicyProjectionCTA />,
  FULL_FLEXIBLE: () => <FullFlexible showCurrencySwitch showPolicyProjectionCTA />,
  INSURED_UNIVERSITY: () => <InsuredUniversity showCurrencySwitch showPolicyProjectionCTA />,
};

const queryClient = new QueryClient();

export function SimulationContainer() {
  const { simulation_type } = getQueryParams<SimulationContainerQueryParams>();

  return (
    <QueryClientProvider client={queryClient}>
      <CurrencyContextProvider>
        <div className="simulation-container vh-100">
          {simulationRenderMap[simulation_type]()}
        </div>
      </CurrencyContextProvider>
    </QueryClientProvider>
  );
}
