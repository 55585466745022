/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  Block, BlockTitle, FieldDisplayer, FieldDisplayerCard, MonthlySavingsDisplayerCard,
} from 'app/components/common';
import { getWording } from 'app/constants/wording/wording';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import { isTransfer } from 'app/utils/apvContribution';
import { useTransformCurrency as r } from 'app/utils/hooks/useTransformCurrency';
import {
  RegimeLiteral,
  SavingsOption,
  WithAPVTransfer,
  WithExtraordinaryContribution,
} from 'app/utils/types';
import React from 'react';

interface TransferForSavingsCapacityCardsProps {
  monthly_pension_with_afp: number
  monthly_pension_afp_with_apv_transfer: number
  monthly_pension_with_savings: number
  apv_regime: Uppercase<RegimeLiteral>
}

function TransferForSavingsCapacityCards({
  monthly_pension_afp_with_apv_transfer,
  monthly_pension_with_afp,
  monthly_pension_with_savings,
  apv_regime,
}: TransferForSavingsCapacityCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <Block className="mb-3">
      <BlockTitle
        extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
        primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
        secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
      />

      <div className="row gy-3 gx-4 justify-content-center">
        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-wad-of-money.svg"
            renderText={() => (
              <>
                <span className="text-warning">
                  {`Sólo con ${wording.your} cotización obligatoria, `}
                </span>
                <span>{`${wording.your} pensión podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                secondaryText={{ content: r(monthly_pension_with_afp), color: 'text-dark' }}
              />
            )}
          />
        </div>

        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-banknote.svg"
            renderText={() => (
              <>
                <span className="text-info">{`Si a eso le ${wording.append} ${wording.yours} actuales ahorros APV, `}</span>
                <span>{`${wording.your} pensión podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                primaryText={{ content: currencyPrefix, color: 'text-info' }}
                secondaryText={{ content: r(monthly_pension_afp_with_apv_transfer), color: 'text-dark' }}
              />
            )}
          />
        </div>

        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-hand-money.svg"
            renderText={() => (
              <>
                <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                <span>{`${wording.your} pensión al mes podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{ content: r(monthly_pension_with_savings), color: 'text-dark' }}
              />
            )}
          />
        </div>
      </div>
    </Block>
  );
}

interface BaseSavingsCapacityCardsProps {
  apv_regime: Uppercase<RegimeLiteral>
  monthly_pension_without_savings: number
  monthly_pension_with_savings: number
}

function BaseSavingsCapacityCards({
  monthly_pension_with_savings, apv_regime, monthly_pension_without_savings,
}: BaseSavingsCapacityCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <Block className="mb-3">
      <BlockTitle
        extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
        primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
        secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
      />

      <div className="row gy-3 gx-4 justify-content-center">
        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-wad-of-money.svg"
            renderText={() => (
              <>
                <span className="text-warning">
                  {`Sólo con ${wording.your} cotización obligatoria, `}
                </span>
                <span>{`${wording.your} pensión podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                secondaryText={{ content: r(monthly_pension_without_savings), color: 'text-dark' }}
              />
            )}
          />
        </div>

        <div className="col-md-6">
          <FieldDisplayerCard
            iconName="circle-hand-money.svg"
            renderText={() => (
              <>
                <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                <span>{`${wording.your} pensión al mes podría ser de:`}</span>
              </>
            )}
            renderFieldDisplayer={() => (
              <FieldDisplayer
                overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                secondaryText={{ content: r(monthly_pension_with_savings), color: 'text-dark' }}
              />
            )}
          />
        </div>
      </div>
    </Block>
  );
}

interface SavingsCapacityCardsProps extends
  TransferForSavingsCapacityCardsProps,
  BaseSavingsCapacityCardsProps,
  WithAPVTransfer,
  WithExtraordinaryContribution {}

function SavingsCapacityCards({
  apv_regime,
  monthly_pension_with_savings,
  monthly_pension_without_savings,
  apv_transfer_amount,
  apv_transfer_origin,
  initial_contribution,
  initial_contribution_kind,
  agreed_deposits,
  agreed_deposits_kind,
  monthly_pension_afp_with_apv_transfer,
  monthly_pension_with_afp,
}: SavingsCapacityCardsProps) {
  const contributionData = {
    apv_transfer_amount,
    apv_transfer_origin,
    initial_contribution,
    initial_contribution_kind,
    agreed_deposits,
    agreed_deposits_kind,
  };

  return isTransfer(contributionData) ? (
    <TransferForSavingsCapacityCards
      monthly_pension_afp_with_apv_transfer={monthly_pension_afp_with_apv_transfer}
      monthly_pension_with_afp={monthly_pension_with_afp}
      monthly_pension_with_savings={monthly_pension_with_savings}
      apv_regime={apv_regime}
    />
  )
    : (
      <BaseSavingsCapacityCards
        monthly_pension_with_savings={monthly_pension_with_savings}
        monthly_pension_without_savings={monthly_pension_without_savings}
        apv_regime={apv_regime}
      />
    );
}

interface BaseIdealPensionCardsProps extends BaseSavingsCapacityCardsProps {
  apv_regime: Uppercase<RegimeLiteral>
  monthly_savings: number
  monthly_pension_without_savings: number
  monthly_pension_with_savings: number
}

function BaseIdealPensionCards({
  monthly_pension_without_savings,
  apv_regime,
  monthly_savings,
  monthly_pension_with_savings,
}: BaseIdealPensionCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <>
      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
          primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
          secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
        />

        <div className="row gy-3 gx-4 justify-content-center">
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-wad-of-money.svg"
              renderText={() => (
                <>
                  <span className="text-warning">
                    {`Sólo con ${wording.your} cotización obligatoria, `}
                  </span>
                  <span>{`${wording.your} pensión podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                  primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                  secondaryText={{ content: r(monthly_pension_without_savings), color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-hand-money.svg"
              renderText={() => (
                <>
                  <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                  <span>{`${wording.your} pensión al mes podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                  primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                  secondaryText={{ content: r(monthly_pension_with_savings), color: 'text-dark' }}
                />
              )}
            />
          </div>
        </div>
      </Block>

      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center mb-3"
          primaryText={{ content: `¿Cómo lo ${wording.willReach}?`, color: 'text-primary' }}
        />

        <div className="row gx-4 gy-3">
          <div className="col-md-7 mx-auto">
            <MonthlySavingsDisplayerCard
              apv_regime={apv_regime}
              monthly_savings={monthly_savings}
            />
          </div>
        </div>
      </Block>
    </>
  );
}

interface TransferIdealPensionCardsProps extends TransferForSavingsCapacityCardsProps {
  monthly_savings: number
}

function TransferIdealPensionCards({
  apv_regime,
  monthly_pension_afp_with_apv_transfer,
  monthly_pension_with_afp,
  monthly_pension_with_savings,
  monthly_savings,
}: TransferIdealPensionCardsProps) {
  const { currencyPrefix } = useCurrencyContext();
  const { wording } = getWording();

  return (
    <>
      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center d-flex flex-column gap-1 mb-3_5"
          primaryText={{ content: `Revisemos ${wording.your} simulación según el Régimen seleccionado `, color: 'text-primary' }}
          secondaryText={{ content: `APV Régimen ${apv_regime}:` }}
        />

        <div className="row gy-3 gx-4 justify-content-center">
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-wad-of-money.svg"
              renderText={() => (
                <>
                  <span className="text-warning">
                    {`Sólo con ${wording.your} cotización obligatoria, `}
                  </span>
                  <span>{`${wording.your} pensión podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-warning"
                  primaryText={{ content: currencyPrefix, color: 'text-warning' }}
                  secondaryText={{ content: r(monthly_pension_with_afp), color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-banknote.svg"
              renderText={() => (
                <>
                  <span className="text-info">{`Si a eso le ${wording.append} ${wording.yours} actuales ahorros APV, `}</span>
                  <span>{`${wording.your} pensión podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-info"
                  primaryText={{ content: currencyPrefix, color: 'text-info' }}
                  secondaryText={{ content: r(monthly_pension_afp_with_apv_transfer), color: 'text-dark' }}
                />
              )}
            />
          </div>

        </div>
      </Block>

      <Block className="mb-3">
        <BlockTitle
          extraClassName="text-center mb-3"
          primaryText={{ content: `¿Cómo lo ${wording.willReach}?`, color: 'text-primary' }}
        />

        <div className="row gy-3 gx-4 justify-content-center">
          <div className="col-md-6">
            <FieldDisplayerCard
              iconName="circle-hand-money.svg"
              renderText={() => (
                <>
                  <span className="text-secondary">{'Con nuevos aportes en APV, '}</span>
                  <span>{`${wording.your} pensión al mes podría ser de:`}</span>
                </>
              )}
              renderFieldDisplayer={() => (
                <FieldDisplayer
                  overwrittenClassName="fs-4 fw-bold px-2_5 py-2 d-inline-block rounded-4 w-100 bg-light-secondary"
                  primaryText={{ content: currencyPrefix, color: 'text-secondary' }}
                  secondaryText={{ content: r(monthly_pension_with_savings), color: 'text-dark' }}
                />
              )}
            />
          </div>

          <div className="col-md-6">
            <MonthlySavingsDisplayerCard
              apv_regime={apv_regime}
              monthly_savings={monthly_savings}
            />
          </div>
        </div>
      </Block>
    </>
  );
}

interface IdealPensionCardsProps extends
  BaseIdealPensionCardsProps,
  TransferIdealPensionCardsProps,
  WithAPVTransfer,
  WithExtraordinaryContribution {}

function IdealPensionCards({
  apv_regime,
  apv_transfer_amount,
  apv_transfer_origin,
  initial_contribution,
  initial_contribution_kind,
  agreed_deposits,
  agreed_deposits_kind,
  monthly_pension_afp_with_apv_transfer,
  monthly_pension_with_afp,
  monthly_savings,
  monthly_pension_with_savings,
  monthly_pension_without_savings,
}: IdealPensionCardsProps) {
  const contributionData = {
    apv_transfer_amount,
    apv_transfer_origin,
    initial_contribution,
    initial_contribution_kind,
    agreed_deposits,
    agreed_deposits_kind,
  };

  return isTransfer(contributionData) ? (
    <TransferIdealPensionCards
      apv_regime={apv_regime}
      monthly_savings={monthly_savings}
      monthly_pension_afp_with_apv_transfer={monthly_pension_afp_with_apv_transfer}
      monthly_pension_with_afp={monthly_pension_with_afp}
      monthly_pension_with_savings={monthly_pension_with_savings}
    />
  )
    : (
      <BaseIdealPensionCards
        apv_regime={apv_regime}
        monthly_savings={monthly_savings}
        monthly_pension_with_savings={monthly_pension_with_savings}
        monthly_pension_without_savings={monthly_pension_without_savings}
      />
    );
}

interface SavingsUnbundlingCardsProps extends
  IdealPensionCardsProps,
  SavingsCapacityCardsProps,
  WithAPVTransfer,
  WithExtraordinaryContribution {
  savings_option: SavingsOption
}

export function ApvLifeSavingsUnblundingCards({
  apv_regime,
  apv_transfer_amount,
  apv_transfer_origin,
  initial_contribution,
  initial_contribution_kind,
  agreed_deposits,
  agreed_deposits_kind,
  monthly_savings,
  savings_option,
  monthly_pension_with_savings,
  monthly_pension_without_savings,
  monthly_pension_afp_with_apv_transfer,
  monthly_pension_with_afp,
}: SavingsUnbundlingCardsProps) {
  return savings_option === 'ideal_pension' ? (
    <IdealPensionCards
      monthly_pension_afp_with_apv_transfer={monthly_pension_afp_with_apv_transfer}
      monthly_pension_with_afp={monthly_pension_with_afp}
      apv_regime={apv_regime}
      apv_transfer_amount={apv_transfer_amount}
      apv_transfer_origin={apv_transfer_origin}
      initial_contribution={initial_contribution}
      initial_contribution_kind={initial_contribution_kind}
      agreed_deposits={agreed_deposits}
      agreed_deposits_kind={agreed_deposits_kind}
      monthly_savings={monthly_savings}
      monthly_pension_with_savings={monthly_pension_with_savings}
      monthly_pension_without_savings={monthly_pension_without_savings}
    />
  )
    : (
      <SavingsCapacityCards
        monthly_pension_afp_with_apv_transfer={monthly_pension_afp_with_apv_transfer}
        monthly_pension_with_afp={monthly_pension_with_afp}
        apv_regime={apv_regime}
        apv_transfer_amount={apv_transfer_amount}
        apv_transfer_origin={apv_transfer_origin}
        initial_contribution={initial_contribution}
        initial_contribution_kind={initial_contribution_kind}
        agreed_deposits={agreed_deposits}
        agreed_deposits_kind={agreed_deposits_kind}
        monthly_pension_with_savings={monthly_pension_with_savings}
        monthly_pension_without_savings={monthly_pension_without_savings}
      />
    );
}
